/* div globale de la page connexion */

.merge-existing-account-svg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 84vh;
    max-height: 100%;
    margin-top: 10vh;
}

.merge-existing-account-svg svg{
    top: 10vh;
}

/* icon makee-diamond-login */

.makee-diamond-login {
    display: none;
}

/* partie à gauche de la page connexion
(seulement l'email et mdp) */

.merge-existing-account {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 8rem);
    justify-content: center;
    width: 40%;
    margin-left: 10vw;
}

.merge-existing-account h1 {
    margin-bottom: 2.5rem;
}

/* lien vers la page d'inscription */

p .merge-existing-account-link {
    color: #F86767;
}

/* formulaire de connexion */

.merge-existing-account form {
    display: flex;
    flex-direction: column;
}

.merge-existing-account form input {
    border-radius: 5px;
    font-weight: 400;
    color: #122236;
}

#password-helper-text {
    margin-bottom: 0;
}

/* lien vers le mot de passe oublié */

.merge-existing-account-link {
    margin-top: 0.9rem;
    color: var(--actionprimary50);
    text-align: center;
}

/* réglage des marges du formulaire */

.css-239a0v>:not(style) {
    margin-top: 0 !important;
    margin-bottom: 37.5px;
}

/* image à droite (diamand) */

.diamond {
    margin-right: -2.5vw;
}

@media screen and (max-width: 1200px) {
    .diamond {
        width: 40%;
    }

}

@media screen and (max-width: 768px) {

    .merge-existing-account {
        width: 100%;
        padding: 0 3rem;
        display: flex;
        justify-content: center;
        height: 100vh;
        margin: 0;
        text-align: center;
    }

    /* on retire le diamand */
    .diamond {
        display: none;
    }

    /* on affiche le petit diamand au dessus du formulaire */
    .makee-diamond-login {
        display: block;
        margin: 0 auto 3rem auto;
    }

}
