.container-admin-infos-challenge-project {
    margin-top: 2rem;
}

.admin-infos-challenge-project-header {
    width: 100%;
    display: flex;
    gap: 1rem;
}
.p-annonce{
    font-style: italic !important;
    color: var(--actionprimary50) !important;
    font-weight: 600 !important;
}
.admin-infos-challenge-project-header-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid var(--primary30);
    padding: 20px 0px;
}

.container-admin-infos-challenge-project h2 {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 700;
}

.container-admin-infos-challenge-project p {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 400;
}

.admin-infos-challenge-project-header-content-stepper {
    width: 80%;
    display: flex;
    justify-content: center;
}

.admin-infos-challenge-project-body-content-header-top {
    margin-top: 2rem;
}

.admin-infos-challenge-project-body-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 5rem;
}

.admin-infos-challenge-project-body-content-header-bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-infos-challenge-project-body-content-body {
    width: 90%;
    margin: 2rem auto;
}

.admin-infos-challenge-project-body-content-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-infos-challenge-project-body-content-footer-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.admin-infos-challenge-project-list {
    position: relative !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background-color: var(--primary10) !important;
}

.admin-infos-challenge-project-list img {
    /*
    height: 12rem;
     */
}

.admin-infos-challenge-project-list-disqualifed {
    background-color: var(--primary30) !important;
}

.admin-infos-challenge-project-list-disqualifed img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.typo-card-h3 {
    color: var(--primary90) !important;
    font-family: var(--font-secondary) !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
}

.typo-card-h4 {
    color: var(--primary90) !important;
    font-family: var(--font-secondary) !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
}

.typo-card-p {
    text-align: left !important;
    color: var(--primary90) !important;
    font-family: var(--font-secondary) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.typo-card-bottom {
    padding: 1.3rem !important;
    background-color: white !important;
    border: 2px solid var(--primary10) !important;
    border-radius: 0 0 10px 10px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.list-project {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    transition: all 0.85s ease-in-out;
}

.showList {
    /*opacity: 1;*/
    /*transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;*/
    /*animation: showList 1.5s cubic-bezier(1,-0.01, 0, 1) forwards;*/
    display: flex;
}
.profilesPictureProject{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.profilesPictureProject img{
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    border-radius: 50%;
}
@keyframes showList {
    from {
        transform: translateX(-200%);
    }
    to {
        transform: translateX(0%);
    }
}

.hideList {
    /*opacity: 0;*/
    /*transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;*/
    /*animation: hideList 1.5s cubic-bezier(1,-0.01, 0, 1) forwards;*/
    display: none;
}

@keyframes hideList {
    from {
        width: auto;
        height: auto;
        opacity: 1;
        transform: translateX(0);
    }
    to {
        width: 0;
        height: 0;
        opacity: 0;
        transform: translateX(200%);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .typo-card-bottom {
        flex-direction: column;
    }
}

@media screen and (min-width: 600px) and (max-width: 820px) {
    .typo-card-bottom {
        flex-direction: column;
    }

    .typo-card-dateDeposit {
        text-align: center !important;
    }
}

@media screen and (max-width: 400px) {
    .typo-card-bottom {
        flex-direction: column;
    }

    .typo-card-dateDeposit {
        text-align: center !important;
    }
}

.typo-card-bottom-error {
    border: 2px solid var(--error50) !important;
    background-color: var(--error10) !important;
}

.typo-card-bottom-error .typo-card-p {
    color: var(--error50) !important;
    font-weight: 600 !important;
}

.typo-card-bottom-disqualified {
    border: 2px solid var(--primary30) !important;
    background: var(--primary10) !important;
}
.list-projects-sorted{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media screen and (max-width: 900px) {
    .admin-infos-challenge-project-header {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .admin-infos-challenge-project-header-content {
        width: 100%;
    }

    .admin-infos-challenge-project-header-content:nth-child(1) {
        border-bottom: none;
        border-radius: 10px 10px 0 0;
    }

    .admin-infos-challenge-project-header-content:nth-child(2) {
        border-top: none;
        border-radius: 0 0 10px 10px;
    }
}