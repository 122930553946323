.profile-summary {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 8rem);
    align-items: center;
    margin-top: 10vh;
}

.profile-summary h1 {
    text-align: center;
}

.profile-summary-infos {
    width: 44.5vw;
}

.card-date-name-container {
    width: 36vw;
}

.div-profile-summary {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.profile-summary-infos-container {
    width: 100%;
}

.profile-summary-infos-container .paragraphs {
    text-align: left;
    margin-top: 2.5rem;
    margin-bottom: 0.6rem;
}

.profile-summary-infos-container button {
    margin-top: 5rem;
}

.profile-summary-infos-container .link {
    padding-top: 1rem;
    text-align: center;
    color: var(--actionprimary50);
    display: block;
    border: none;
    background-color: transparent;
    width: 100%;
    margin: 0;
    text-decoration: underline;
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    cursor: pointer;
}

.css-1nrlq1o-MuiFormControl-root {
    width: 100%;
}

.profile-summary-infos-container select {
    text-align: left !important;
    width: 100%;
}

.card-date-name-container-mobile {
    display: none;
}

.makee-diamond-login {
        display: none;
    }

@media screen and (max-width: 1000px) {
    .div-profile-summary {
        margin-top: 3rem;
    }

    .card-date-name-container {
        display: none;
    }

    .card-date-name-container-mobile {
        display: block;
        padding: 2rem 0 0 0;
    }

    .profile-summary-infos {
        width: 80%;
        max-width: 37rem;
    }

    .profile-summary-infos-container {
        width: 100%;
    }

    .profile-summary {
        align-items: normal;
    }
}

@media screen and (max-width: 768px) {
    .div-profile-summary {
        margin-top: 10rem;
    }

    .profile-summary h1 {
        font-size: 1.5rem;
    }

    .makee-diamond-login {
        display: block;
        margin: 3rem auto 2rem auto;
    }

    .profile-summary-infos {
        margin-top: -8rem;
    }

    .profile-summary {
        height: auto;
        margin-bottom: 3rem;
        align-items: auto;
    }

    .profile-summary-infos-container button {
        margin-top: 2rem;
    }}
