.admin-challenge-planning {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.block-planning-manual {
    width: 100%;
    display: flex;
    justify-content: center;
}

.img-planning-public {
    width: 50%;
}

@media screen and (max-width: 900px) {
    .admin-challenge-planning {
        width: 100%;
    }
}