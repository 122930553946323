.loginHeader2 {
    width: 100%;
    min-height: 4rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 0 10px 2px #e5e5e5;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;
}

.loginHeader2-icon {
    width: 10rem;
    height: 4rem;
}

.loginHeader2-icon:hover {
    cursor: pointer;
}

.translate-icon-header {
    margin-left: auto;
    margin-right: 3rem;
}

@media screen and (max-width: 900px) {
    /*.loginHeader2{*/
    /*    bottom: 0;*/
    /*    top: auto;*/
    /*    border-radius: 15px 15px 0 0;*/
    /*}*/
    .translate-icon-header {
        position: absolute;
        right: 1rem;
        margin-right: 0;
        margin-left: 0;
    }
}

.translateIcon {
    position: absolute;
    right: 2rem;
}
