/*Empêcher un margin automatique de material ui moche*/
/* clown emoji */
.css-ha3bif-MuiTimelineItem-root::before {
    flex: 0 !important;
    padding: 0 !important;
}


.admin-challenge-planning .planning-header {
    text-align: center;
}

.admin-challenge-planning .planning-item {
    border-radius: 10px;
    border: 1px solid var(--primary30);
    height: fit-content;
    padding: 0.9rem;
}

.planning-item.has-selection {
    height: 5.8rem;
}

@media screen and (min-width: 768px) {

    .admin-challenge-planning .planning-item {
        width: 50vw;
    }

    .planning-item.has-selection {
        height: fit-content;
    }

    .planning-item.has-selection>div {
        display: flex;
        justify-content: space-between;
    }
}

.switch-planning-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem
}

.admin-challenge-planning {
    margin-bottom: 5rem;
}

.file-button {
    position: relative;
    height: fit-content;
}

.file-button-modif {
    position: relative;
    height: fit-content;
    margin-top: -1.5rem !important;
    width: 100%;
}

.file {
    cursor: pointer !important;
}



.img-planning {
    width: 100%;
    margin-left: auto;
    display: block;
    object-fit: contain;
}

.block-modif-send {
    display: flex;
    gap: 1rem;
    max-height: 1rem;
    align-items: center;
    margin-top: 2rem;
}

.manual-planning {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.container-buttons-planning {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

@media screen and (max-width: 900px) {
    .manual-planning {
        width: 80%;
    }
}

.btn-modifier {
    margin-top: 5rem;
}