.admin-infos-challenge-projects-selections {
    margin-bottom: 10rem;
    width: 100%;
}

.admin-infos-challenge-projects-selections-header {
    background-color: var(--primary10);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.admin-infos-challenge-projects-selections h3 {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 700;
}

.admin-infos-challenge-projects-selections-header-step-name {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 20px;
    font-weight: 700;
}

.admin-infos-challenge-projects-selections-header-step-date-selection {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 500;
}

.admin-infos-challenge-projects-selections-list {
    background-color: var(--primary10) !important;
    position: relative;
    border: 2px solid transparent;
    /*border-width: 2px;*/
    /*border-image: none !important;*/
    /*border-image-slice: 1 !important;*/
    transition: border 0.2s ease-in-out;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.card-selected {
    /*position: relative !important;*/
    /*border-width: 2px !important;*/
    /*border-image: var(--gradient) !important;*/
    /*border-image-slice: 1 !important;*/
    /*border-radius: 10px !important;*/
    border: 2px solid var(--actionprimary50);
}

.admin-infos-challenge-projects-selections-list-img {
    /*height: 500px;*/
    min-height: 168px;
    height: 168px;
}

.admin-infos-challenge-projects-selections-content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-infos-challenge-projects-selections-content .description {
    text-align: left;
}

.admin-infos-challenge-projects-selections-content-project-subchallenge {
    /*border: 1px solid red;*/
    margin-top: 1rem;
}

.admin-infos-challenge-projects-selections-content-project-subchallenge h4 {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 18px;
    font-weight: 700;
}

.admin-infos-challenge-projects-selections-content-project {
    /*border: 1px solid red;*/
}

.admin-infos-challenge-projects-selections-content-criterias {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-infos-challenge-projects-selections-content-criterias h4 {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 700;
}

.admin-infos-challenge-projects-selections-content-criterias .text-infos-criterias {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 400;
    width: 50%;
    margin-bottom: 3rem;
}

.admin-infos-challenge-projects-selections-content-criteria {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.admin-infos-challenge-projects-selections-content-criteria:nth-child(3) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.w-90 {
    width: 90%;
}

.description.w-90 {
    text-align: center;
}

.admin-infos-challenge-projects-selections-content-criteria p {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    text-align: left;
}

.admin-infos-challenge-projects-selections-content-criteria-text {
    display: flex;
    flex-direction: column;
    gap: .0rem;
}

.admin-infos-challenge-projects-selections-content-criteria-text .name {
    font-size: 14px;
    font-weight: 600;
}

.admin-infos-challenge-projects-selections-content-criteria-text .description {
    /*border: 1px solid red;*/
}

.admin-infos-challenge-projects-selections-content-criteria-points p {
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
    text-align: right;
}

.admin-infos-challenge-projects-selections-content-projects {
    /*border: 1px solid red;*/
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    margin-top: 2rem;
}

.admin-infos-challenge-projects-selections-footer {
    position: fixed;
    bottom: 0;
    left: 16.4vw;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 1rem;
    border-top: 1px solid var(--primary30);
}

.admin-infos-challenge-projects-selections-footer-buttons {
    display: flex;
    gap: 1rem;
    width: 75%;
    max-width: 90%;
}

.block-switchbutton-text {
    display: flex;
    gap: 1rem;
}

.block-switchbutton-text p {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /*line-height: 20px;*/
}

.admin-infos-challenge-projects-selections-typo-card-bottom {
    padding: 1.3rem !important;
    background-color: var(--primary10) !important;
    border-radius: 0 0 10px 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    border-top: 1px solid var(--primary30) !important;
}

.admin-infos-challenge-projects-selections .error-message {
    color: var(--error50);
    text-align: center;
    font-family: var(--font-secondary);
    background-color: var(--error10);
    padding: 1rem;
    width: fit-content;
    border-radius: 10px;
    border: 2px solid var(--error50);
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
}

.admin-infos-challenge-projects-selection-modal-content {
    /*border: 1px solid red;*/
    border-radius: 10px;
    /*overflow: hidden;*/
    height: 10rem;
    display: flex;
    background-color: var(--primary10);
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 400;
}

.admin-infos-challenge-projects-selection-modal-content .img-project {
    width: 40%;
    height: auto;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.admin-infos-challenge-projects-selection-modal-content .block-description {
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin-infos-challenge-projects-selection-modal-content .block-description .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: .3rem;
    /*width: 20rem;*/
    width: 85%;
    color: var(--primary90);
    font-family: var(--font-secondary);
    text-align: left;
}

.admin-infos-challenge-projects-selection-modal-content .block-description .description .team {
    font-size: 14px;
    font-weight: 400;
}

.admin-infos-challenge-projects-selection-modal-content .block-description .description .project-name {
    font-size: 18px;
    font-weight: 700;
}

.admin-infos-challenge-projects-selections-modal {
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.admin-infos-challenge-projects-selections-modal-block-selections {
    /*border: 1px solid black;*/
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 900px) {
    .admin-infos-challenge-projects-selections-header {
        margin-top: -2.7rem;
    }

    .admin-infos-challenge-projects-selections-footer {
        left: 0;
        z-index: 999;
    }

    .admin-infos-challenge-projects-selections-footer-buttons {
        width: 95%;
    }

    .admin-infos-challenge-projects-selections-content-criterias .text-infos-criterias {
        width: 100%;
    }

    .admin-infos-challenge-projects-selections-content-criteria {
        flex-direction: column;
        align-items: start;
    }

    .admin-infos-challenge-projects-selections-content-criteria-points p {
        text-align: start;
    }

    .admin-infos-challenge-projects-selections-content-criterias {
        width: 90%;
    }

    .admin-infos-challenge-projects-selection-modal-content {
        flex-direction: column;
        gap: 1rem;
        height: max-content;
        padding-bottom: 1rem;
    }

    .admin-infos-challenge-projects-selection-modal-content .img-project {
        width: 100%;
        height: 10rem;
        border-radius: 10px 10px 0 0;
    }

    .admin-infos-challenge-projects-selection-modal-content .block-description {
        width: 100%;
    }

    .admin-infos-challenge-projects-selection-modal-content .block-description .description .team {
        font-size: 12px;
    }

    .admin-infos-challenge-projects-selection-modal-content .block-description .description .project-name {
        font-size: 16px;
    }
}
