.admin-infos-challenge-general .link-challenge {
    background-color: black;
}

.admin-infos-challenge-general .list-requiered {
    width: fit-content;
    margin: auto;
}

.admin-infos-challenge-general .steps-infos {
    background-color: var(--primary10);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
}

.admin-infos-challenge-general .steps-infos-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
}

.admin-infos-challenge-general .steps-infos-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}